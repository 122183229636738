@import './quill.css';
@import '../../node_modules/codemirror/lib/codemirror.css';
@import '../../node_modules/codemirror/theme/material.css';
@import '../../node_modules/hamburgers/dist/hamburgers.css';

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
          local('Roboto Light '),
          local('Roboto-Light'),
          url('../../node_modules/typeface-roboto/files/roboto-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
          url('../../node_modules/typeface-roboto/files/roboto-latin-300.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
          local('Roboto Regular '),
          local('Roboto-Regular'),
          url('../../node_modules/typeface-roboto/files/roboto-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
          url('../../node_modules/typeface-roboto/files/roboto-latin-400.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
          local('Roboto Medium '),
          local('Roboto-Medium'),
          url('../../node_modules/typeface-roboto/files/roboto-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
          url('../../node_modules/typeface-roboto/files/roboto-latin-500.woff') format('woff'); /* Modern Browsers */
}

@tailwind base;

@tailwind components;

@tailwind utilities;

@tailwind screens;

.styled-description {
  @apply whitespace-pre-wrap tracking-wide font-sans;
}

.styled-description a {
  @apply text-hub-green;
}

.styled-description a:hover {
  @apply underline;
}

.styled-description ol {
  @apply px-4;
}

.styled-description ol li {
  @apply list-decimal;
}

.styled-description ul {
  @apply px-4;
}

.styled-description ul li {
  @apply list-disc;
}

.styled-description h1 {
  @apply text-2xl mb-2 font-bold;
}

.styled-description h2 {
  @apply text-xl mb-2 font-bold;
}

.styled-description h3 {
  @apply text-lg mb-1 font-medium;
}

.styled-description .ql-font-sans {
  @apply font-sans;
}

.styled-description .ql-font-serif {
  @apply font-serif;
}

.styled-description .ql-font-monospace {
  @apply font-mono;
}

.styled-description .ql-align-center {
  text-align: center;
}

.styled-description .ql-align-right {
  text-align: right;
}

.styled-description .ql-align-justify {
  text-align: justify;
  text-justify: inter-word;
}

.styled-description img {
  @apply mr-3;
}

.perfect-justification {
  text-align: justify;
  text-justify: inter-word;
}

.attendee-table {
  @apply min-w-full border border-gray-200 rounded-md overflow-hidden;
}

.attendee-table thead {
  @apply sticky top-0;
}

.attendee-table thead th {
  @apply px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider;
}

.attendee-table tbody {
  @apply bg-white;
}

.attendee-table tbody td{
  @apply px-6 py-4 whitespace-no-wrap border-b border-gray-200;
}

.attendee-table tbody tr:hover {
  @apply bg-gray-50;
}

.upload-box {
  @apply bg-hub-grey w-full h-20 shadow p-2 relative;
}

.upload-box.large {
  @apply h-40;
}

.upload-box > div.upload-cta {
  @apply absolute top-0 bottom-0 left-0 right-0 text-center text-xl text-white font-medium flex items-center justify-center;
  display: none;
  background: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.upload-box:hover > div.upload-cta {
  display: flex;
}

.btn-hub-green-solid {
  @apply text-white h-8 px-3 py-1 text-sm text-center rounded leading-none bg-hub-green;
}

.btn-hub-green {
  @apply border-solid border border-hub-green rounded-md text-hub-green px-4 py-1 w-auto;
}

.base-input {
  @apply rounded-md outline-none py-1 px-2 shadow-inner-input block w-full text-sm leading-5 text-gray-800 border border-gray-700;
}

.base-input::placeholder {
  color: #777777;
}

#skip-to-event-home:focus {
  @apply top-auto;
}

.skip-content-button {
  @apply fixed top-0 -mt-12 bg-white left-0 rounded border-2 border-black p-2 z-200;
}

.skip-content-button:focus {
  @apply top-5 mt-0;
}

@screen sm {
  .base-input {
    @apply border-0;
  }
}

.time-input {
  @apply rounded-md outline-none py-1 px-2 shadow-inner-input w-auto inline-block text-sm leading-5 text-gray-800 border border-gray-100;
}

.time-input.in-panel {
  display: block !important;
  width: 100% !important;
}

.speaker-card .cardDescription.show-hide,
.exhibitor-card .cardDescription.show-hide {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}

.speaker-card:hover .cardDescription.show-hide,
.exhibitor-card:hover .cardDescription.show-hide {
  max-height: 100px;
  transition: max-height 0.25s ease-in;
}

.vendor-card-gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0.05%, rgba(0, 0, 0, 0.50) 100%);
}

.display-item-card-gradient {
  background: linear-gradient(to bottom,  rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.775746) 100%);
}

.vendor-page-gradient {
  background: linear-gradient(180deg, rgba(0,0,0,.0001) 12.05%, rgba(0,0,0,.50) 100%);
  /*background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 14.05%, rgba(0, 0, 0, 0.835746) 100%);*/
}

.vendor-card-shadow {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
}

.shadow-inner-input {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.6);
}

.footer-shadow {
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.5);
}

.upload-waiting {
  background: rgba(0,120,212,0);
  background: linear-gradient(to right, rgba(0,120,212,0) 0%, rgba(0,120,212,1) 51%, rgba(0,120,212,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0078d4', endColorstr='#0078d4', GradientType=1 );
  height: 4px;
  -webkit-animation: progressBarAnimation 2s linear infinite;
  animation: progressBarAnimation 2s linear infinite;
}

@keyframes progressBarAnimation {
  0% {
    left: -50%;
  }
  100% {
    left: 100%;
  }
}

.widgetContainer > span {
  border: none !important;
}

.fitVids-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.fitVids-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtubeContainer {
  position: relative;
  width: 100%;
  /*height: 0;*/
  /*padding-bottom: 5%;*/
  overflow: hidden;
}

.youtubeContainer iframe, .wistiaPlayer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.youtubeChatContainer {
  position: relative;
  /*padding-bottom: 56.25%;*/
  overflow: hidden;
}

.youtubeChatContainer iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* React Crop */
.ReactCrop {
  /*@apply relative overflow-hidden max-h-full flex justify-center items-center h-hero;*/
  @apply relative overflow-hidden inline-block max-w-full max-h-full;
  cursor: crosshair;
}

.ReactCrop:focus {
  outline: none;
}
.ReactCrop > div:first-child {
  /*@apply flex;*/
  height: 100%;
  /*max-height: 100%;*/
  @apply block max-h-full;
}
.ReactCrop--disabled, .ReactCrop--locked {
  cursor: inherit;
}
.ReactCrop__image {
  /*@apply block h-full w-auto max-w-full object-contain;*/
  @apply max-w-full max-h-full;
  touch-action: manipulation;
}
.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
  touch-action: manipulation;
  border: 1px solid;
  border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
  border-image-slice: 1;
  border-image-repeat: repeat;
}
.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}
.ReactCrop--circular-crop .ReactCrop__crop-selection {
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px white, 0 0 0 9999em rgba(0, 0, 0, 0.5);
}
.ReactCrop--invisible-crop .ReactCrop__crop-selection {
  display: none;
}
.ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after, .ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
  content: '';
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
}
.ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after {
  width: 1px;
  height: 100%;
}
.ReactCrop__rule-of-thirds-vt::before {
  left: 33.3333%;
  left: calc(100% / 3);
}
.ReactCrop__rule-of-thirds-vt::after {
  left: 66.6666%;
  left: calc(100% / 3 * 2);
}
.ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
  width: 100%;
  height: 1px;
}
.ReactCrop__rule-of-thirds-hz::before {
  top: 33.3333%;
  top: calc(100% / 3);
}
.ReactCrop__rule-of-thirds-hz::after {
  top: 66.6666%;
  top: calc(100% / 3 * 2);
}
.ReactCrop__drag-handle {
  position: absolute;
}
.ReactCrop__drag-handle::after {
  position: absolute;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  outline: 1px solid transparent;
}
.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: nw-resize;
}
.ReactCrop .ord-nw::after {
  top: 0;
  left: 0;
}
.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize;
}
.ReactCrop .ord-n::after {
  top: 0;
}
.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: ne-resize;
}
.ReactCrop .ord-ne::after {
  top: 0;
  right: 0;
}
.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: e-resize;
}
.ReactCrop .ord-e::after {
  right: 0;
}
.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  margin-bottom: -5px;
  margin-right: -5px;
  cursor: se-resize;
}
.ReactCrop .ord-se::after {
  bottom: 0;
  right: 0;
}
.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: s-resize;
}
.ReactCrop .ord-s::after {
  bottom: 0;
}
.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: sw-resize;
}
.ReactCrop .ord-sw::after {
  bottom: 0;
  left: 0;
}
.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: w-resize;
}
.ReactCrop .ord-w::after {
  left: 0;
}
.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}
.ReactCrop__drag-bar {
  position: absolute;
}
.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}
.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -3px;
}
.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
}
.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  margin-left: -3px;
}
.ReactCrop--new-crop .ReactCrop__drag-bar,
.ReactCrop--new-crop .ReactCrop__drag-handle,
.ReactCrop--fixed-aspect .ReactCrop__drag-bar {
  display: none;
}
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}
@media (pointer: coarse) {
  .ReactCrop .ord-n,
  .ReactCrop .ord-e,
  .ReactCrop .ord-s,
  .ReactCrop .ord-w {
    display: none;
  }
  .ReactCrop__drag-handle {
    width: 24px;
    height: 24px;
  }
}


/* target safari only for a flexbox fix. */
@media not all and (min-resolution:.001dpcm) {
  @media {
    .flex-fix {
      flex: 1 0 auto !important;
    }
  }
}

.badge {
  @apply inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 bg-gray-200 text-gray-800;
}

.badge.badge-success {
  @apply bg-green-200 text-green-800;
}

.badge.badge-warning {
  @apply bg-yellow-300 text-black;
}

.badge.badge-failed {
  @apply bg-red-500 text-white;
}

body:not(.loggedin-user) iframe#launcher,
body:not(.loggedin-user) iframe#webWidget {
  display: none;
}

body.inside-iframe > #root > footer,
body.inside-iframe > #root > .admin-bar,
body.inside-iframe > #root > header + section,
body.inside-vendor-iframe > #root > header {
  display: none;
}

body.inside-iframe > #root > header > main > a > img {
  height: 3rem;
}

body.inside-iframe > #root > header > main > a > div {
  font-size: .95rem;
}

iframe#launcher, iframe#webWidget {
  left: 0 !important;
  right: auto !important;
}

iframe#launcher {
  left: 0 !important;
  right: auto !important;
  height: 50px;
  max-height: 551px;
  min-height: 50px;
  width: 113px;
}

.pointer {
  cursor: pointer;
}

.password-input {
  @apply rounded-md py-1 px-2 border border-gray-100 shadow-inner-input text-sm leading-5 text-gray-800;
}

.tile .background {
  background-color: #000000;
  width: 100%;
  padding-top: 56.25%; /* Hard-coded 16:9 aspect ratio */
}

.tile.local video {
  transform: scale(-1, 1);
}

.tile .loading {
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  font-size: 14px;
  line-height: 17px;
}

.tile .controls {
  display: none;
}

.tile:hover .controls {
  display: flex;
}

.call-message {
  width: auto;
  padding: 20px 30px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
}

.call-message.error {
  color: #d81a1a;
  background-color: #ffffff;
}

.call-message-header {
  font-weight: bold;
}

.tray-button {
  width: 44px;
  height: 44px;
  border: none;
  background-color: transparent;
  margin: 0 5px;
}

.tray-button:disabled {
  opacity: 33%;
}

.tray-button:enabled {
  cursor: pointer;
}

/* Acts as the first button in a new "group" of buttons separated from those to the left of it */
.tray-button.new-group {
  margin-left: auto;
}

.participantTile {
  /*@apply w-1/3 mb-4 mx-3 bg-black h-auto rounded;*/
  @apply bg-black w-auto h-auto rounded;
}

.participantTile.transparent {
  @apply bg-transparent;
}

.mini-booth .participantTile {
  height: 10rem;
}

.mobile-booth .participantTile {
  height: 10rem;
}

table.header-w-strip {

}

table.header-w-strip thead tr th {
  @apply px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider;
}

/* to use tooltips: role="tooltip" aria-label="Your Name Here" */
[role='tooltip']:hover:after {
  opacity: 1;
  visibility: visible;
}

[role='tooltip']:after {
  content: attr(aria-label);
  @apply px-3 py-0 rounded absolute bottom-0 bg-black text-sm text-white capitalize;
  right: 100%;
  white-space: nowrap;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[role='tooltip'].tooltip-right:after {
 left: 115%;
  right: auto;
}

[role='tooltip'] {
  position: relative;
}

.cam-parent * {
  border: 0 none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

.cam-parent {
  position: absolute;
  overflow: hidden;
  -moz-box-align: center;
  align-items: center;
  display: flex;
  border: 0 none;
  margin: 0;
  padding: 0;
  top: 0;
  left: 256.76px;
  width: 612.479px;
  height: 344.52px;
}

.cam-parent > div {
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
}

.cam-parent > div div.video-container:first-child {
  position: relative;
  overflow: hidden;
  align-self: center;
  -moz-box-flex: 1;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  border: medium none;
}

.cam-parent > div div.video-container:first-child video {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  align-self: center;
  flex-grow: 1;
}

.cam-parent > div div.video-container:first-child audio {
  /* no styles*/
}

.cam-parent > div div.mute-label {
  height: 44px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  padding: 0 22px 0 14px;
  background: rgba(0, 0, 0, 0.45) none repeat scroll 0 0;
  transition: background 0.2s ease 0s;
}

.cam-parent > div div.mute-label {
  height: 44px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  padding: 0 22px 0 14px;
  background: rgba(0, 0, 0, 0.45) none repeat scroll 0 0;
  transition: background 0.2s ease 0s;
}

.cam-parent > div div.enlarge-label {
  transition: opacity 0s ease 0s;
  position: absolute;
  top: 14px;
  right: 14px;
  width: 36px;
  height: 36px;
  z-index: 10;
  opacity: 0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  overflow-y: auto;
}

.modal-dialog {
  margin: 1.75rem auto;
  display: block;
  flex: 1 1 1px;
  max-width: 238px
}










.ql-editor {
  box-sizing: border-box;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.ql-editor > * {
  cursor: text;
}
.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol,
.ql-editor ul {
  padding-left: 1.5em;
}
.ql-editor ol > li,
.ql-editor ul > li {
  list-style-type: none;
}
.ql-editor ul > li::before {
  content: '\2022';
}
.ql-editor ul[data-checked=true],
.ql-editor ul[data-checked=false] {
  pointer-events: none;
}
.ql-editor ul[data-checked=true] > li *,
.ql-editor ul[data-checked=false] > li * {
  pointer-events: all;
}
.ql-editor ul[data-checked=true] > li::before,
.ql-editor ul[data-checked=false] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}
.ql-editor ul[data-checked=true] > li::before {
  content: '\2611';
}
.ql-editor ul[data-checked=false] > li::before {
  content: '\2610';
}
.ql-editor li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
.ql-editor li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}
.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
.ql-editor ol li.ql-direction-rtl,
.ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}
.ql-editor ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
.ql-editor ol li:before {
  content: counter(list-0, decimal) '. ';
}
.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
}
.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) '. ';
}
.ql-editor ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
}
.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) '. ';
}
.ql-editor ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
}
.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) '. ';
}
.ql-editor ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
}
.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) '. ';
}
.ql-editor ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
}
.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) '. ';
}
.ql-editor ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
}
.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) '. ';
}
.ql-editor ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
}
.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) '. ';
}
.ql-editor ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}
.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
}
.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) '. ';
}
.ql-editor ol li.ql-indent-8 {
  counter-reset: list-9;
}
.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}
.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) '. ';
}
.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}
.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}
.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}
.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}
.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}
.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}
.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}
.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}
.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}
.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}
.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}
.ql-editor .ql-video {
  display: block;
  max-width: 100%;
}
.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}
.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}
.ql-editor .ql-bg-black {
  background-color: #000;
}
.ql-editor .ql-bg-red {
  background-color: #e60000;
}
.ql-editor .ql-bg-orange {
  background-color: #f90;
}
.ql-editor .ql-bg-yellow {
  background-color: #ff0;
}
.ql-editor .ql-bg-green {
  background-color: #008a00;
}
.ql-editor .ql-bg-blue {
  background-color: #06c;
}
.ql-editor .ql-bg-purple {
  background-color: #93f;
}
.ql-editor .ql-color-white {
  color: #fff;
}
.ql-editor .ql-color-red {
  color: #e60000;
}
.ql-editor .ql-color-orange {
  color: #f90;
}
.ql-editor .ql-color-yellow {
  color: #ff0;
}
.ql-editor .ql-color-green {
  color: #008a00;
}
.ql-editor .ql-color-blue {
  color: #06c;
}
.ql-editor .ql-color-purple {
  color: #93f;
}
.ql-editor .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}
.ql-editor .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
.ql-editor .ql-size-small {
  font-size: 0.75em;
}
.ql-editor .ql-size-large {
  font-size: 1.5em;
}
.ql-editor .ql-size-huge {
  font-size: 2.5em;
}
.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}
.ql-editor .ql-align-center {
  text-align: center;
}
.ql-editor .ql-align-justify {
  text-align: justify;
}
.ql-editor .ql-align-right {
  text-align: right;
}
.ql-editor.ql-blank::before {
  color: rgba(0,0,0,0.6);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}

.CodeMirror {
  height: 600px;
}

.hamburger-box, .hamburger-inner {
  width: 2rem;
}

.hamburger-inner::before, .hamburger-inner::after {
  width: 2rem;
  background-color: inherit;
}

.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: inherit;
}

.calendar-add-dropdown > ul > li:hover {
  background-color:#edf2f7;
}

@media screen and (max-width: 500px) {
  .btn-row-responsive {
    display: block;
  }
}

#age-error {
  max-width:400px;
  word-wrap:break-word;
}

.customWidget {
  height: 100%;
  width: 100%;
}

.customWidget iframe {
  width: 100%;
  height: 100%;
}